import { http } from "../utils/http";
export const userQuery = (data) =>
  http.post("/weilan/api/user/info/query", data);
export const userDel = (data) =>
  http.post("/weilan/api/user/info/delete", data);
export const userAdd = (data) => http.post("/weilan/api/user/info/add", data);
export const userEdit = (data) =>
  http.post("/weilan/api/user/info/modify", data);
export const roleQuery = (data) =>
  http.post("/weilan/api/role/info/query", data);
export const roleDel = (data) =>
  http.post("/weilan/api/role/info/delete", data);
export const roleAdd = (data) => http.post("/weilan/api/role/info/add", data);
export const roleEdit = (data) =>
  http.post("/weilan/api/role/info/modify", data);
export const moduleQuery = (data) =>
  http.post("/weilan/api/module/info/query", data);
export const logQuery = (data) =>
  http.post("/weilan/api/log/manager/query", data);
