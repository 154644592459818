<template>
  <el-dialog
    :title="type === 1 ? '新增账号信息' : '修改账号信息'"
    :visible.sync="visible"
    width="20%"
    @close="close"
  >
    <el-form :model="userForm" ref="userForm" :rules="rules">
      <el-form-item prop="name" label="用户名" label-width="80px">
        <el-input v-model="userForm.name"></el-input>
      </el-form-item>
      <el-form-item prop="account" label="账号" label-width="80px">
        <el-input v-model="userForm.account"></el-input>
      </el-form-item>
      <el-form-item prop="password" label="密码" label-width="80px">
        <el-input v-model="userForm.password"></el-input>
      </el-form-item>
      <el-form-item prop="customerId" label="所属公司" label-width="80px">
        <el-select v-model="userForm.customerId" placeholder="请选择">
          <el-option
            v-for="item in customerOptions"
            :key="item.id"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="roleId" label="系统角色" label-width="80px">
        <el-select v-model="userForm.roleId" placeholder="请选择">
          <el-option
            v-for="item in roleOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-if="type != 3">
      <!-- <el-button @click="dialogVisible = false" type="info" size="mini"
            >返回</el-button
            > -->
      <el-button
        class="btn-blue"
        type="primary"
        style="color: #000"
        @click="onSubmit('userForm')"
        >{{
          type === 1 ? $t("addDevice.add") : $t("addDevice.edit")
        }}</el-button
      >
      <!-- <el-button type="primary" size="mini" v-else>修改</el-button> -->
    </span>
  </el-dialog>
</template>
<script>
import { userAdd, userEdit, roleQuery } from "@/api/user.js";
import { customerQuery } from "@/api/customer.js";
import { deepClone } from "@/utils/deepClone";
import i18n from "@/lang";
export default {
  data() {
    return {
      visible: false,
      type: 1, //1新增 2编辑
      userForm: {},
      rules: {
        name: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
        password: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
        account: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
        customerId: [
          {
            required: true,
            message: "不为空",
            trigger: ["change"],
          },
        ],
        roleId: [
          {
            required: true,
            message: "不为空",
            trigger: ["change"],
          },
        ],
      },
      customerOptions: [],
      roleOptions: [],
    };
  },
  mounted() {},
  methods: {
    getCustomer() {
      customerQuery()
        .then((res) => {
          console.log(res.data.data.list);
          this.customerOptions = res.data.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRole() {
      roleQuery()
        .then((res) => {
          console.log(res.data.data.list);
          this.roleOptions = res.data.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.userForm);
          let obj = JSON.parse(JSON.stringify(this.userForm));
          console.log(obj);
          if (this.type === 1) {
            this.addInfo(obj);
          } else if (this.type === 2) {
            this.editInfo(obj);
          }
        }
      });
    },
    addInfo(obj) {
      console.log("add", obj);
      userAdd(obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: i18n.t("prompt.operateSuccessfully"),
          });
          this.$emit("refresh");
          this.visible = false;
        } else {
          this.$message({
            type: "error",
            message: i18n.t("prompt.operationFailure") + res.data.message,
          });
        }
      });
    },
    editInfo(obj) {
      console.log("edit", obj);
      userEdit(obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: i18n.t("prompt.operateSuccessfully"),
          });
          this.$emit("refresh");
          this.visible = false;
        } else {
          this.$message({
            type: "error",
            message: i18n.t("prompt.operationFailure") + res.data.message,
          });
        }
      });
    },
    open(type, row) {
      console.log(typeof type, type);
      this.visible = true;
      this.getCustomer();
      this.getRole();
      this.type = type;
      if (type === 1) {
        this.viewDisable = false;
        this.userForm = {};
      } else if (this.type === 2) {
        this.userForm = deepClone(row);
        console.log(row, this.rateForm, "hhhhhh222");
      } else if (type === 3) {
        this.userForm = deepClone(row);
      }
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
<style lang="stylus" scoped>
/deep/ .el-input{
  width 90% !important
}
/deep/ .el-select{
  width 100% !important
}
/deep/ .el-select .el-input{
  width 90% !important
}
</style>
